import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from '@app/services/account.service';
import { CommonUtilityService } from '@app/services/common-utility.service';
import { UserService } from '@app/services/user.service';
import { NGXLogger } from 'ngx-logger';
import { distinctUntilChanged, pairwise, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit, OnDestroy {
  title: string;
  pageHeading: string;
  emailCheckForm: UntypedFormGroup;
  registrationForm: UntypedFormGroup;
  submitted: boolean;
  statusList: any;
  plansList: any;
  franchiseeList: any;
  convertedFromList: any;
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private accountService: AccountService,
    private fb: UntypedFormBuilder,
    private utilityService: CommonUtilityService,
    private userService: UserService,
    private logger: NGXLogger
  ) {
    this.pageHeading = 'Register Test Account';
    this.statusList = [
      {id: 2, label: 'Live', value: 'live', key: 'live'},
      {id: 3, label: 'Test', value: 'test', key: 'test'},
      {id: 4, label: 'Sandbox', value: 'sandbox', key: 'sandbox'},
      {id: 5, label: 'Delete', value: 'delete', key: 'delete'}
    ];
    this.plansList = [
      {id: 1, label: 'WriteUp', value: '8'},
      {id: 2, label: 'Startup', value: '7'},
      {id: 3, label: 'Business', value: '9'},
      {id: 4, label: 'Professional', value: '1'},
      {id: 5, label: 'Professional w/eCommerce', value: '2'},
      {id: 6, label: 'Bank Accounting Online', value: '3'}
    ];
    this.franchiseeList = [
      {id: 2, value: '1000', name: '1000 - Bookkeeping zoom'},
      {id: 3, value: '1001', name: '1001 - SalesDemo'},
      {id: 4, value: '1002', name: '1002 - CFOToday'},
      {id: 5, value: '1004', name: '1004 - BooxKeeping'},
      {id: 6, value: '9999', name: '9999 - Testing'}
    ];
    this.convertedFromList = [
      {id: 1, value: 'Empty', name: 'Empty'},
      {id: 2, value: 'QBO', name: 'QBO'},
      {id: 3, value: 'BAD', name: 'BAD'},
      {id: 4, value: 'QBD', name: 'QBD'},
      {id: 5, value: 'Custom', name: 'Custom'}
    ];
  }

  ngOnInit(): void {
    this.route.parent.parent.data
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.title = `Register Account | ${data.franchisee.companyName}`;
        this.titleService.setTitle(this.title);
      });
    this.emailCheckForm = this.fb.group({
      email: ['', [Validators.required, Validators.email, Validators.maxLength(50)]]
    });
    this.registrationForm = this.fb.group({
      email: ['', [Validators.required, Validators.email, Validators.maxLength(50)]],
      firstname: ['', [Validators.required, Validators.maxLength(75)]],
      lastname: ['', [Validators.required, Validators.maxLength(75)]],
      companyName: ['', [Validators.required, Validators.maxLength(100)]],
      planName: ['1', [Validators.required]],
      type: ['live', [Validators.required]],
      convertedFrom: ['Empty', [Validators.required]],
      franchisee: ['9999', [Validators.required]],
      billed: [{ value: false, disabled: true }]
    });
    this.registrationForm.get('email').valueChanges.pipe(
      distinctUntilChanged(),
      pairwise() // gets a pair of old and new value
    )
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe(([oldValue]) => {
        if (oldValue !== '') {
          this.submitted = false;
          this.registrationForm.get('email').setValue('');
        }
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
  }

  get checkUserForm() {
    return this.emailCheckForm.controls;
  }

  get registerAccountForm() {
    return this.registrationForm.controls;
  }

  checkEmail() {
    if (this.emailCheckForm.valid) {
      this.userService.checkUserIsExists(this.checkUserForm.email.value).then((userDetails: any) => {
        this.logger.info('INFO: -> RegisterComponent -> this.userService.checkUserIsExists -> userDetails', userDetails);
        this.submitted = true;
        if (userDetails.status === 'NewUser') {
          this.utilityService.displaySuccessToast(this.pageHeading, 'This is a new user.' );
        } else {
          this.utilityService.displaySuccessToast(this.pageHeading, 'This is an existing user.');
        }
        this.registrationForm.patchValue({
          email: this.checkUserForm.email.value,
          firstname: userDetails.firstname,
          lastname: userDetails.lastname,
          companyName: userDetails.company
        });
      }).catch(error => {
        this.logger.error('ERROR: -> RegisterComponent -> this.userService.checkUserIsExists -> error', error);
        this.utilityService.displayErrorToastWithTechDetails(this.pageHeading, error.error);
      });
    } else {
      this.utilityService.displayErrorToast(this.pageHeading, 'Please fill all the required field.');
    }
  }

  registerAccount() {
    if (this.registrationForm.valid) {
      console.log('DEBUG: -> RegisterComponent -> registerAccount ->', this.registrationForm.get('franchisee').value);
      if (this.registrationForm.get('franchisee').value === '000') {
        this.utilityService.displayErrorToast(this.pageHeading, 'You can`t create Trial Account with Franchisee 000');
      } else {
        this.accountService.registerAccount(this.registrationForm.value).then((response: any) => {
          this.logger.info('INFO: -> RegisterComponent -> this.accountService.registerAccount -> response', response);
          this.utilityService.displaySuccessToast(this.pageHeading, response.message);
          this.submitted = false;
          this.emailCheckForm.get('email').setValue('');
          this.emailCheckForm.get('email').markAsUntouched();
          this.emailCheckForm.get('email').markAsPristine();
        }).catch(error => {
          this.logger.error('ERROR: -> RegisterComponent -> this.accountService.registerAccount -> error', error);
          this.utilityService.displayErrorToastWithTechDetails(this.pageHeading, error.error);
        });
      }
    } else {
      this.utilityService.displayErrorToast(this.pageHeading, 'Please fill all the required field.');
    }
  }

}
